import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite'

const firebaseConfig = {
  apiKey: 'AIzaSyBCST2LQo4EqEHdIZucC0n81Pf0UKapCsg',
  authDomain: 'n4863s-a6b55.firebaseapp.com',
  projectId: 'n4863s-a6b55',
  storageBucket: 'n4863s-a6b55.appspot.com',
  messagingSenderId: '331059745517',
  appId: '1:331059745517:web:ff9658d8a9b0abc3c432e4',
}

const app = initializeApp(firebaseConfig)

// getFirestore(app).settings({
//   ignoreUndefinedProperties: true,
// })

const db = getFirestore(app)

export { db, app, collection, getDocs }
