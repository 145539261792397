<template>
  <div id="app">
    <div style="position: absolute; z-index: 1000; bottom: 30px; left: 20px; max-width: 350px; max-height: 200px; background-color: #fff; border-radius: 5px; overflow: hidden; overflow-y: auto; white-space: nowrap">
      <div style="margin: 15px">Flights Shown:</div>
      <ul style="list-style: none; padding-left: 10px; padding-top: 0px">
        <li style="text-overflow: ellipsis; overflow: hidden" :key="i" v-for="(f, i) in flightsList" @click="myClickHandler(f)">{{ f.origin.friendlyLocation }} > {{ f.destination.friendlyLocation }}</li>
      </ul>
    </div>
    <GmapMap :center="{ lat: 33.9705, lng: -80.9952 }" :options="mapOptions" :zoom="8" map-type-id="hybrid" style="height: 100vh; position: unset"> <GmapPolyline :key="i" v-for="(flight, i) in flights" :path="flight.path" :options="options" /> </GmapMap>
  </div>
</template>

<script>
import { db, collection, getDocs } from './firebase.js'

export default {
  name: 'App',
  components: {},
  data: function () {
    return {
      flightsList: [],
      markers: [],
      flights: [],
      flightsBackup: [],
      mapOptions: { labels: true },
      options: { geodesic: true, strokeColor: '#FF0000', strokeOpacity: 1.0, strokeWeight: 4 },
    }
  },
  methods: {
    initMap() {},
    myClickHandler(flight) {
      this.flights = this.flightsBackup
      this.flights = this.flights.filter((x) => x.flightId === flight.flightId)
    },
  },
  created: async function () {
    const flightCol = collection(db, 'flights')
    const flightsSnapshot = await getDocs(flightCol)
    const flightsList = flightsSnapshot.docs.map((doc) => doc.data())
    this.flightsList = flightsList

    this.flightsList.forEach((x) => {
      let track = x.trackLog.filter((x) => x.latitude)
      let path = track.map((x) => {
        return { lat: parseFloat(x.latitude), lng: parseFloat(x.longitude) }
      })
      this.flights.push({ path: path, flightId: x.flightId })
    })

    this.flightsBackup = this.flights
    this.initMap()
  },
}
</script>

<style></style>
