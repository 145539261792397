import Vue from 'vue'
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBJ_-J4OmhNJgTZgPUR5i4Rija1saS8liw',
    libraries: 'places',
  },
})

new Vue({
  render: (h) => h(App),
}).$mount('#app')
